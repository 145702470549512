<template>
  <intersect
    v-if="isShowProductsIntersect"
    @enter="productIntersect = 'Intersected'"
  >
    <section
      v-if="products"
      class="products-week"
      :class="{ 'is-none': !products.length }"
    >
      <div class="container">
        <div v-if="products.length" class="header-block">
          <h2 class="title">{{ $t(title) }}</h2>
          <router-link
            class="header-block__link"
            :to="{ name: 'catalog', params: { weekly: true } }"
            @click.native="gtmHandler"
            >{{ $t("showMore") }}</router-link
          >
        </div>
        <products-list v-if="products" :items="products" />
      </div>
    </section>
  </intersect>
</template>

<script>
import { mapState } from "vuex";
import { getProductsByFilters } from "@/api/products";
import Intersect from "vue-intersect";
import { eventBus } from "@/helpers";
export default {
  name: "WeeklyProductsList",
  props: {
    excludeProduct: {
      type: Number,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },
  components: {
    ProductsList: () => import("@/components/products/ProductsList.vue"),
    Intersect,
  },
  data() {
    return {
      products: null,
      title: "productsWeek",
      isShowProductsIntersect: false,
      productIntersect: "",
    };
  },
  computed: {
    catId() {
      return this.$i18n.locale === "kz" ? 15 : 1;
    },
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    excludeProduct: {
      handler() {
        this.getWeeklyProducts(6);
      },
    },
    selectedCity: {
      deep: true,
      handler() {
        this.getWeeklyProducts();
      },
    },
    productIntersect: {
      handler() {
        if (this.productIntersect === "Intersected") {
          this.viewProducts(this.products);
        }
      },
    },
  },
  created() {
    this.getWeeklyProducts();
  },
  mounted() {
    if (this.$router.app._route.fullPath === "/products") {
      this.title = "otherGoods";
    }
    setTimeout(() => {
      this.isShowProductsIntersect = true;
    }, 1000);
  },
  methods: {
    getWeeklyProducts() {
      getProductsByFilters(
        {
          categories: [],
          discountTypes: [1],
          shopTypes: [],
        },
        { field: "id", sortType: "asc" },
        { page: 1, pageSize: this.pageSize },
        this.excludeProduct
      ).then((data) => {
        this.products = data.data;
      });
    },
    viewProducts(products) {
      eventBus.$emit("gtm", {
        event: "view_item_list",
        type: "view_item_list",
        listName: "блок Каталог скидок",
        items: products,
        category: "",
      });
    },
  },
};
</script>
